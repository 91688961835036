.calendarWrapper {
  height: 75vh;
}

.fc-content:hover {
  cursor: pointer;
}

.calendar {
  & .fc-button-primary {
    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(199, 199, 199, 0.4);
    border-color: rgba(199, 199, 199, 0);
    &:hover{
      background-color: rgba(199, 199, 199, 0.6);
    }
  }
  .fc-today {
    background-color: rgba(199, 199, 199, 0.4) !important;
  }
  &.dark {
    & .fc-button-primary {
      color: #fff;
      background-color: #424242;
      border-color: #424242;
      &:hover{
        background-color: #4a4a4a;
    }
    }
    & .fc-today {
      background-color: rgba(100, 100, 100, 0.38) !important;
    }
  }
}

.fc-button:focus{
    outline: none;
    box-shadow: none;
}

.fc-icon-add:before{
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" focusable="false" aria-hidden="true" ><g fill="white"><path stroke="white" stroke-width="0.5" stroke-linecap="round" d="M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1z"></path></g></svg>');
}
