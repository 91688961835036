.datetimeField {
  min-width: 220px !important;
}

#responsive-dialog-title {
  padding-bottom: 0;
}

.modalInfo {
  color: #cccccc;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.modalInfo:first-child {
  margin-top: 0;
}

#modalDetails {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.songList {
  margin-top: 1.1rem;
}

#modalCloseButton {
  position: absolute;
  right: 10px;
  top: 10px;
}
